var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _vm._l(_vm.charges, function(charge, index) {
        return [
          _c(
            "v-col",
            { key: index, attrs: { lg: "4", md: "3", sm: "6" } },
            [_c("screen-charge", { attrs: { charge: charge, mode: "print" } })],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }